import React from "react"
import PageLayout from "../components/PageLayout"


export default function Home() {
  return (<div>
    <PageLayout>
      <h1>Home</h1>

    </PageLayout>
  </div>)
}
