import React from 'react'
import {NavBar,Footer} from './'
import "../styles/main.css"


export default function PageLayout({children}) {
    return (
        <div className="layout">
            <NavBar/>
            <div className="content">
                {children}
            </div>


            <Footer/>

            

        </div>
    )
}
