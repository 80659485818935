import { Link } from 'gatsby'
import React from 'react'

export  function NavBar() {
    return (
        <nav>
            <div className="logo">
                <Link to="/"><img src="/rwicon.png" alt="RammWorks Logo" width="50" /></Link>
            </div>
            <div>
                
                <Link to="/">Home</Link>
                <Link to="/About">About</Link>
                <Link to="/Projects">Projects</Link>
                <Link to="/Contact">Contact</Link>
            </div>
        </nav>
    )
}
